import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import config from 'config/common';
import lib from 'lib/commons';

import Sidebar from './Sidebar';
import ModalHistory from 'components/ModalHistory';
import ModalUserInfo from '../../components/ModalUserInfo';

const Header = ({
	lng,
	user: currentUser = {},
	user: {
		user: {
			nickname = '',
			id: userId = null,
			uid = null,
			user_in_game: userInGame = {},
		} = {},
		isGettingHistory = false,
		isExchangingShopHistory = false,
		currentLeague = {},
		userHistory = [],
		params = {},
		currentWeekLeagues = [],
		exchangeHistory = [],
	} = {},

	getHistory,
	exchangeShopHistory,
}) => {
	let history = useHistory();
	const [modalStatus, setModalStatus] = useState(false);
	const closeModal = () => setModalStatus(false);

	const [modalUserInfo, setModalUserInfo] = useState(false);
	const closeModalUserInfo = () => setModalUserInfo(false);

	const handleScrollLeague = (e) => {
		e.preventDefault();
		let exists =
			currentWeekLeagues.filter(
				(item) => item?.has_collaborator_permission == true
			) || [];

		if (!userId) {
			lib.login();
			return false;
		}

		if (!currentLeague?.league?.id) {
			lib.showMessage(`Bạn chưa đăng ký giải đấu nào, hãy đăng ký giải đấu.`);
			return false;
		}

		if (currentLeague?.matches.length == 0 && currentLeague?.league?.id) {
			lib.showMessage(
				`Giải đấu bạn đăng ký chưa đủ VĐV để có thể tạo nhánh đấu. Bạn hãy kiểm tra lại sau.`
			);
			return false;
		}

		if (
			(currentLeague?.matches.length > 0 && currentLeague?.league?.id) ||
			(exists.length > 0 && currentLeague?.matches.length > 0)
		) {
			let section = document.getElementById('brackets');
			section.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			<div id="header">
				<div className="top-bar">
					<div className="container container--content">
						<div className="row align-items-center justify-content-center">
							<div className="col-3">
								<a href="https://fconline.garena.vn" className="logo">
									<img
										src="https://cdn.vn.garenanow.com/web/fo4/events/fco-news-2023-react/images/logo.png"
										alt=""
									/>
								</a>
							</div>
							<div className="col-9">
								<a
									href="#"
									className="menu-link"
									onClick={(e) => {
										e.preventDefault();
										let section = document.getElementById('ambassador');
										section.scrollIntoView({ behavior: 'smooth' });
									}}
								>
									Đại sứ
								</a>
								<a
									href="#"
									className="menu-link"
									onClick={(e) => {
										e.preventDefault();
										lib.showImage(params?.guide_url);
									}}
								>
									Hướng dẫn
								</a>
								<a
									href="#"
									className="menu-link"
									onClick={(e) => {
										e.preventDefault();
										setModalStatus(true);
									}}
								>
									Lịch sử
								</a>
								<a
									href="#"
									className="menu-link"
									onClick={(e) => {
										e.preventDefault();
										lib.showImage(params?.reward_url);
									}}
								>
									Quà nhận được
								</a>
								{/* {userId && ( */}
								<>
									<span className="user-info">
										HLV <strong className="nickname">{nickname}</strong>
									</span>
									<a
										href="#"
										className="icon-info"
										onClick={(e) => {
											e.preventDefault();
											setModalUserInfo(true);
										}}
									>
										<img
											src="https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2023/T3/GIAIDAU/info.png"
											alt=""
										/>
									</a>
								</>
								{/* )} */}
								<a
									href="#"
									className="my-tournament"
									onClick={(e) => handleScrollLeague(e)}
								>
									<img src="/images/my-tournament.png" alt="" />
								</a>
								<div className="header-action">
									{!userId ? (
										<a
											href="/user/login"
											className="btn-login"
											onClick={(e) => {
												if (!eventStarted) {
													e.preventDefault();
													lib.showMessage(config.demoMsg);
													return false;
												}
											}}
										>
											<span>Đăng nhập</span>
											<img src="/images/icon-login.png" alt="" />
										</a>
									) : (
										<a href="/user/logout" className="btn-logout">
											<span>Đăng xuất</span>
											<img src="/images/icon-logout.png" alt="" />
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalStatus && (
				<ModalHistory
					userHistory={userHistory}
					isGettingHistory={isGettingHistory}
					getHistory={getHistory}
					modalStatus={modalStatus}
					closeModal={closeModal}
					isExchangingShopHistory={isExchangingShopHistory}
					exchangeShopHistory={exchangeShopHistory}
					exchangeHistory={exchangeHistory}
				/>
			)}
			{modalUserInfo && (
				<ModalUserInfo
					modalStatus={modalUserInfo}
					closeModal={closeModalUserInfo}
					nickname={nickname}
					uid={uid}
					data={currentUser}
					userInGame={userInGame}
				/>
			)}
		</>
	);
};

export default Header;
