import React, { useState } from 'react';
import Modal from 'react-modal';
import Scrollbar from 'react-scrollbars-custom';
import { Formik } from 'formik';
import * as yup from 'yup';

import config from 'config/common';
import lib from 'lib/commons';

const ModalUserInfo = ({
	data: { isUpdatingInfo = false, allAddress = {} },
	nickname,
	uid,
	userInGame,
	modalStatus,
	closeModal,
}) => {
	const afterOpenModal = () => {};

	const selfClose = (event) => {
		event.preventDefault();
		closeModal();
	};

	const allProvinceObj =
		allAddress && allAddress?.all_province
			? lib.toObj(allAddress?.all_province, 'province_id')
			: {};
	const allDistrictObj =
		allAddress && allAddress?.all_district
			? lib.toObj(allAddress?.all_district, 'district_id')
			: {};

	return (
		<Modal
			isOpen={modalStatus}
			onAfterOpen={afterOpenModal}
			onRequestClose={selfClose}
			contentLabel="Example Modal"
			portalClassName="ReactModalPortal"
			overlayClassName=""
			className="animated fadeInDown faster"
		>
			<h2>Thông tin chi tiết</h2>
			<div className="modal-description">
				<form>
					<div className="hr-form"></div>
					<div className="row">
						<div className="col-6">
							<div className="row align-items-center">
								<div className="col-4">
									<label>Họ và tên</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										name="name"
										value={userInGame.name || ''}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="row align-items-center">
								<div className="col-4">
									<label>
										Điện thoại <strong>*</strong>{' '}
									</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										name="phone"
										value={userInGame.phone || ''}
										readOnly
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-6">
							<div className="row align-items-center">
								<div className="col-4">
									<label>UID</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										name="facebook"
										value={uid || ''}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="row align-items-center">
								<div className="col-4">
									<label>Tên HLV</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										name="facebook"
										value={userInGame?.account_name || ''}
										readOnly
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-6">
							<div className="row">
								<div className="col-4">
									<label className="cmnd">CMND</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										name="national_id"
										value={userInGame.national_id || ''}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="row">
								<div className="col-6">
									<div className="selection selection--register">
										<span className="not-arrow">
											{/* {typeSearch == 1 ? 'Tên CLB' : 'UID Đội Trưởng'} */}
											{userInGame?.province_id
												? allProvinceObj[userInGame?.province_id]?.province_name
												: 'Tỉnh/Thành phố'}
										</span>
									</div>
								</div>
								<div className="col-6">
									<div className="selection selection--register ">
										<span className="not-arrow">
											{userInGame?.district_id
												? allDistrictObj[userInGame?.district_id]?.district_name
												: 'Quận/huyện'}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hr-form"></div>
					<div className="row justify-content-center">
						<div className="col-4">
							<a
								href="#"
								className="btn btn--submit btn--fw"
								onClick={(e) => {
									e.preventDefault();
									closeModal();
								}}
							>
								Xác nhận
							</a>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ModalUserInfo;
